@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --foreground-rgb: 229 229 229;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(160deg, #060606 0%, #252525 100%);
  font-family: 'Space Mono', monospace;
  overflow-x: hidden;
}

button[data-testid="rk-connect-button"] {
  background-color: #36d2cd!important;
}

button:disabled{
  cursor: not-allowed!important;
}

.collapse {
  display: none;
}

.placeholder {
  margin: 0 auto;
  max-width: 200px;
  min-height: 100px;
  background-color: #eee;
 }
 
 @keyframes placeHolderShimmer{
     0%{
         background-position: -468px 0
     }
     100%{
         background-position: 468px 0
     }
 }
 
 .animated-background {
     animation-duration: 1.25s;
     animation-fill-mode: forwards;
     animation-iteration-count: infinite;
     animation-name: placeHolderShimmer;
     animation-timing-function: linear;
     background: rgb(9 9 9);
     background: linear-gradient(to right, #090909 10%, #1b1b1b 18%, #090909 33%);
     background-size: 800px 104px;
     height: 50px;
     position: relative;
 }

::selection {
  background: #86e4e1;
  color: #060606;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #171717;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #31bdb9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #269390;
}

.space-grotesk {
  font-family: 'Space Grotesk', sans-serif;
}

.space-mono {
  font-family: 'Space Mono', monospace;
}

.diagnol-bg {
  background: repeating-linear-gradient(
    -45deg,
    #0a0a0a,
    #0a0a0a 5px,
    #171717 0,
    #171717 10px
  );
}